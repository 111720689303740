import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/mnt/d/ShapeGanicGatsby/src/components/blog/layout.js";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Disclosure from "../../../components/blog/legal/affiliateMarketing.de.mdx";
import BlogOverview from "../../../components/blog/overview/blogOverview.de.mdx";
import Layout from "../../../components/blog/layout.de";
export const query = graphql`
  {
    mdx(
      slug: { eq: "wie-verliert-man-am-schnellsten-fett-am-bauch/index.de" }
    ) {
      frontmatter {
        main_image {
          publicURL
        }
        image_first {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_first_alt
        image_first_desc
        image_first_credit_text
        image_first_credit_link
        image_second {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_second_alt
        image_second_desc
        image_second_credit_text
        image_second_credit_link
        image_third {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_third_alt
        image_third_desc
        image_third_credit_text
        image_third_credit_link
        image_fourth {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_fourth_alt
        image_fourth_desc
        image_fourth_credit_text
        image_fourth_credit_link
        image_faq {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_faq_alt
        image_faq_desc
        image_faq_credit_text
        image_faq_credit_link
        image_app_promotion {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_app_promotion_alt
        image_app_promotion_desc
        image_app_promotion_credit_text
        image_app_promotion_credit_link
        date(formatString: "D. MMMM YYYY", locale: "de-DE")
      }
      fields {
        readingTime {
          minutes
        }
      }
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const SEO = makeShortcode("SEO");
const Title = makeShortcode("Title");
const Image = makeShortcode("Image");
const NavLinkGlobal = makeShortcode("NavLinkGlobal");
const AffiliateLink = makeShortcode("AffiliateLink");
const AppPromotion = makeShortcode("AppPromotion");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO lang={props.pageContext.frontmatter.lang} description={props.pageContext.frontmatter.summary} title={props.pageContext.frontmatter.title} keywords={props.pageContext.frontmatter.keywords} imageUrl={props.data.mdx.frontmatter.main_image.publicURL} mdxType="SEO" />
    <Title title={props.pageContext.frontmatter.title} date={props.data.mdx.frontmatter.date} readingTime={props.data.mdx.fields.readingTime.minutes} mdxType="Title" />
    <p>
  <b>{props.pageContext.frontmatter.summary}</b> Erfahre alles darüber wie
  Philipp es geschafft hat stolze <b>21,5 kg</b> in nur <b>38 Wochen</b>{" "}
  abzunehmen und gleichzeitig Muskeln aufzubauen. Das Rezept zum Erfolg waren 3
  bis 4 kurze Trainingseinheiten die Woche und ein ausgewogener und gesunder
  Diätplan. Erfahre in diesem Artikel alle Details und begleite Philipp auf
  seiner Reise zur Traumfigur!
    </p>
    <Image image={getImage(props.data.mdx.frontmatter.image_first)} imageAlt={props.data.mdx.frontmatter.image_first_alt} imageDesc={props.data.mdx.frontmatter.image_first_desc} imageAuthor={props.data.mdx.frontmatter.image_first_credit_text} imageLink={props.data.mdx.frontmatter.image_first_credit_link} mdxType="Image" />
    <h2>{`Radikale Kehrtwende - Philipps Beweggründe`}</h2>
    <p>
  Bevor du bis in's kleinste Detail erfährst, was Philipp getan hat, um seine
  Traumfigur zu erreichen, lass mich dir vorher von den Anfängen der Reise
  erzählen. Es ist sehr wichtig für dich zu verstehen, was die Motivation und
  der innere Antrieb hinter dieser unglaublichen Leistung waren. Auch möchte ich
  dir hier erzählen, wie der Kontakt zustande kam und wie die Zusammenarbeit
  abgelaufen ist. Auch wirst du verstehen wieso es sehr wichtig ist seine
  Kalorien und Makronährstoffe sowie sein Körpergewicht zu tracken.
    </p>
    <h3>{`Die Motivation`}</h3>
    <p>
  Was unterscheidet einen erfolgreichen Menschen von einem nicht erfolgreichen?
  Wieso erreicht eine Person seine Ziele, während eine andere immer wieder
  scheitert? Wie ist es möglich, dass Philipp 21,5 kg in nur 38 Wochen
  abgenommen hat und das bei gleichzeitigem Muskelaufbau, während manch anderer
  es nicht schafft ein Mal um den Block zu spazieren? Ist es eine Sache der
  Veranlagung, also eine Frage des Talents? Sind wir nun mal so wie wir sind und
  können nichts daran ändern?
    </p>
    <p>
  Die Wahrheit ist eine sehr unbequeme. Die Wahrheit macht so manche Ausrede und
  Entschulding bedeutungslos. Das was du jetzt hörst wird dich vielleicht aus
  deiner Komfortzone bringen. Der Grund wieso manche von uns es einfach schaffen
  und andere nicht, ist nicht "ich bin halt so und ich kann nichts daran
  ändern". Das was uns unterscheidet ist das, was wir tun, es sind unsere
  Entscheidungen. Es ist keine Frage der Veranlagung! Das einzig Entscheidende
  ist also unsere Motivation, der innere Antrieb, also gute und solide Gründe
  etwas zu tun. Du hast ein Ziel? Wieso möchtest du es erreichen? Was bewegt
  dich? Was sind deine guten Gründe? Schreib sie dir jetzt auf!
    </p>
    <p>
  Philipp hatte sehr gute Gründe. Seine Motivation war solide und beständig. Er
  hatte schon seit mehreren Jahren mit Übergewicht zu kämpfen. Obwohl er sich
  regelmäßig sportlich betätigt hat, gab es dennoch schlechte Gewohnheiten in
  seinem Tagesablauf, die ihn davon abgehalten haben sein Wunschgewicht zu
  erreichen. Auch wollte Philipp sich wohler in seiner Haut fühlen und
  langfristig auf seine Gesundheit achten. Selbstverständlich haben auch
  Eitelkeit, Ehrgeiz und das Streben nach Idealen eine enorme Rolle gespielt.
  Das war Philipps solides mentales Fundament, was ihn letztlich zum
  entschlossenen Handeln bewegt hat.
    </p>
    <Image image={getImage(props.data.mdx.frontmatter.image_second)} imageAlt={props.data.mdx.frontmatter.image_second_alt} imageDesc={props.data.mdx.frontmatter.image_second_desc} imageAuthor={props.data.mdx.frontmatter.image_second_credit_text} imageLink={props.data.mdx.frontmatter.image_second_credit_link} mdxType="Image" />
    <h3>{`Der erste Kontakt`}</h3>
    <p>
  Ich habe kurz vor unserem ersten Kontakt Fotos von einem
  Bodybuilding-Wettkampf auf einer Social Media Plattform gepostet. Das waren
  Fotos auf denen man meine Form vor und nach der Diät sehen konnte. Philipp
  hatte die Fotos gesehen und sie haben ihn offenbar sehr beeindruckt. Das hat
  letztlich dazu geführt, dass er mich voller Tatendrang angeschrieben hat, um
  mehr über meine Strategie zu erfahren.
    </p>
    <p>
  Philipp und Ich haben uns also länger unterhalten und hatten hierbei die
  Gelegenheit ausführlich über die Themen Ernährung und Training zu sprechen.
  Vielen Fragen konnten in diesem ersten Gespräch beantwortet werden und
  insbesondere hat Philipp schnell gemerkt, dass seine Zielvorstellung eine
  andere ist als es meine war und man deshalb einige Aspekte anpassen müsste. Da
  Philipp und Ich gut befreundet sind, habe ich ihm nach kurzer Zeit das Angebot
  gemacht ihn in den Bereichen Ernährung, Training und Motivation zu coachen.
    </p>
    <p>
  In den darauf folgenden Wochen und Monaten habe ich festgestellt, dass Philipp
  keine leichtfertigen Zusagen macht, die er dann nur halbherzig in die Tat
  umsetzt. Also hat er sich einige Tage Zeit genommen, um ersthaft über die
  Zusammenarbeit nachzudenken. Zum Glück hat er letztlich zugesagt und was dann
  passiert ist, siehst Du ja eindeutig auf den Fotos. Fangen wir im nächsten
  Abschnitt mit seiner Ernährung an!
    </p>
    <h2>{`Du wirst nur abnehmen, wenn Du deine Ernährung in den Griff kriegst!`}</h2>
    <p>
  Eine alte Sportlerweisheit besagt, dass der Erfolg zu <b>70 %</b> aus der{" "}
  <b>Ernährung</b> und zu <b>30 %</b> aus dem richtigen <b>Training</b> besteht.
  Dieser Abschnitt widmet sich also der wichtigsten Komponente, um schnell und
  effektiv abzunehmen, nämlich der Ernährung. Aus diesem Grund siehst du im
  Folgenden den ersten Ernährungsplan, den ich Philipp erstellt habe. Er liefert{" "}
  <b>2290 Kalorien</b>, <b>260 g Eiweiß</b>, <b>220 g Kohlenhydrate</b> und{" "}
  <b>40 g Fett</b>. Die prozentuale Makronährstoffverteilung beläuft sich
  hierbei auf <b>45 % Eiweiß</b>, <b>39 % Kohlenhydrate</b> und <b>16 % Fett</b>.
  Um die prozentuale Makronährstoffverteilung einfach und zuverlässig zu bestimmen,
  empfehle ich Dir diese kostenlose <NavLinkGlobal to="/de/#appstores" mdxType="NavLinkGlobal">
    App
  </NavLinkGlobal>. Du fragst dich jetzt bestimmt, wie diese Kalorienmenge bestimmt
  wurde. Tatsächlich handelt es sich hierbei um kein Geheimnis. Eine einfache Beschreibung,
  wie Du deinen Kalorienbedarf berechnest, um deine Traumfigur zu erreichen, findest
  du <NavLinkGlobal to="/de/blog/wie-berechne-ich-meinen-kcal-bedarf" mdxType="NavLinkGlobal">
    HIER
  </NavLinkGlobal>.
    </p>
    <p>
  Den Diätplan werde ich dir im Folgenden Mahlzeit für Mahlzeit erläutern, damit
  du genau nachvollziehen kannst, mit welchem strategischen Hintergrund die
  jeweiligen Nahrungsmittel gewählt wurden.
    </p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Mahlzeit`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Lebensmittel`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Menge`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Frühstück`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Reiswaffeln`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`75 g`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kräuterquark, leicht`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`200 g`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hähnchenbrust Aufschnitt`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`100 g`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Snack`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Vollkornbrot`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40 g`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Käse, 45 % Fett i. Tr.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`35 g`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><AffiliateLink url={props.pageContext.frontmatter.product_whey_url} mdxType="AffiliateLink">{`Whey Protein`}</AffiliateLink></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40 g`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Mittagessen`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Reis`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`100 g`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hähnchenbrustfilet`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`300 g`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Brechbohnen`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`150 g`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Abendessen`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Magerquark`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`500 g`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Walnussöl`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10 g`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Snack`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><AffiliateLink url={props.pageContext.frontmatter.product_whey_url} mdxType="AffiliateLink">{`Whey Protein`}</AffiliateLink></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40 g`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Banane`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`125 g`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Frühstück`}</h3>
    <p>
  Das Frühstück ist reich an Eiweiß und Kohlenhydraten und gleichzeitig äußerst
  fettarm. Besonders morgens ist es wichtig deinem Körper einfach verdauliches
  Protein und schnell verfügbare Kohlenhydrate zur Verfügung zu stellen. Hierbei
  stellen der Quark und der Hähnchenbrust Aufschnitt die Eiweißquellen dar. Auch
  erfordert dieses Frühstück keine Vorbereitungszeit. Es ist folglich sehr gut
  geeignet, wenn du nur wenig Zeit zur Verfügung hast.
    </p>
    <h3>{`Erster Snack`}</h3>
    <p>
  Um Heißhunger gar nicht erst aufkommen zu lassen ist es wichtig regelmäßig
  Mahlzeiten zu sich zu nehmen. Im Fall von Philipp sind es 3 etwas größere und
  2 kleine Mahlzeiten. Der erste Snack ist ebenfalls schwerpunktmäßig ein
  Lieferant für Eiweiß und Kohlenhydrate. Neben Protein liefert der Käse hierbei
  auch eine moderate Menge an Fett. Auch hier ist es für Philipp von großer
  Wichtigkeit gewesen, dass es schnell zubereitet ist. Der Käse muss hierbei nur
  auf das Brot gelegt werden. Der Eiweißshake mit Wheyprotein kann in einem
  Shaker überall hin mitgenommen und zubereitet werden. Wie du deinen
  Wheyprotein Shake am besten zu dir nimmst, erfährst du auch{" "}
  <NavLinkGlobal to="/de/blog/was-bedeutet-whey-protein" mdxType="NavLinkGlobal">HIER</NavLinkGlobal>.
    </p>
    <h3>{`Mittagessen`}</h3>
    <p>
  Das Mittagessen stellt hinsichtlich des Nahrungsvolumens die wohl größte
  Mahlzeit dar. Es ist ebenfalls besonders reich an Eiweißen und Kohlenhydraten.
  Zusätzlich liefert es solide Mengen an Ballaststoffen, die vor allem in den
  Brechbohnen vorhanden sind. Selbstverständlich kannst du Reis auch gegen
  Buchweizen tauschen, die noch bessere Nährwerte haben und darüber hinaus eine
  bessere Sättigung aufweisen. Ebenfalls wären Kartoffeln oder gar Süßkartoffeln
  eine sinnvolle Alternative. Auch könntest du die Kohlenhydratquellen
  regelmäßig abwechseln. Auf Nudeln würde ich nicht zurückgreifen. Sie haben
  einen überaus hohen glykämischen Index; sie führen also zu hohen
  Insulinausschüttungen, was schnell zu Heißhunger führen kann. Auch machen sie
  erfahrungsgemäß nicht lange satt, was besonders in einer Diät alles andere als
  hilfreich ist.
    </p>
    <h3>{`Abendessen`}</h3>
    <p>
  Die Makronährstoffzusammensetzung des Abendessens ist jetzt eine ganz andere.
  Hier wird praktisch vollständig auf Kohlenhydrate verzichtet, während
  stattdessen eine Kombination aus Eiweiß und Fett zugeführt wird. Das Besondere
  am Eiweiß im Magerquark ist, dass es sich überwiegend um{" "}
  <NavLinkGlobal to="/de/blog/fuer-was-ist-casein-gut" mdxType="NavLinkGlobal">Casein</NavLinkGlobal>{" "}
  handelt. Casein ist ein sehr langsam verdauliches Eiweiß, welches sich
  hervorragend als Proteinlieferant für die Nachtruhe eignet. So ist dein Körper
  über einen langen Zeitraum mit Aminosäuren versorgt. Falls dir Magerquark
  geschmacklich nicht zusagen sollte, oder du hin und wieder einen gesunden
  Snack brauchst, so kannst du alternativ auf{" "}
  <AffiliateLink url={props.pageContext.frontmatter.product_casein_url} mdxType="AffiliateLink">
    {props.pageContext.frontmatter.product_casein_text}
  </AffiliateLink>{" "}
  Proteinpulver zurückgreifen.
    </p>
    <p>
  Beim Walnussöl handelt es sich um eine sehr gesunde Fettquelle. Es liefert
  mehrfach ungesättigte Omega-3-Fettsäuren, die sehr wichtig für deine
  Gesundheit sind und zudem deine Diätbemühungen unterstützen. Der Irrglaube ist
  nach wie vor weit verbreitet, dass Nahrungsfett fett macht. Nahrungsfett hat
  in erster Linie nichts mit Körperfett zu tun. Letzteres wird vom Körper
  erzeugt, wenn hinsichtlich der Nahrung ein{" "}
  <NavLinkGlobal to="/de/blog/wie-berechne-ich-meinen-kcal-bedarf" mdxType="NavLinkGlobal">
    Kalorienüberschuss
  </NavLinkGlobal>{" "}
  vorliegt. Woher diese Kalorien stammen ist in erster Linie egal! Merke dir
  also: du brauchst unbedingt gesunde Fette in deiner Ernährung unabhängig
  davon, ob du auf Diät bist oder nicht! Eine gute Alternative bzw. Abwechslung
  zum Walnussöl ist Leinöl. Es hat ebenfalls ein ausgezeichnetes Profil an
  Fettsäuren und hat eine andere geschmackliche Note.
    </p>
    <h3>{`Zweiter Snack`}</h3>
    <p>
  Der zweite Snack hat zwei Funktionen. Er wird entweder als Mahlzeit zwischen
  Mittagessen und Abendessen eingenommen oder direkt nach dem Training. Das
  hängt dementsprechend davon ab, ob Du an dem jeweiligen Tag trainierst oder
  nicht. Auch hier handelt es sich um eine Mahlzeit, die praktisch keine
  Zubereitungszeit benötigt. Die hierbei zugeführten Makronährstoffe sind primär
  schnell resorbierbares{" "}
  <NavLinkGlobal to="/de/blog/was-bedeutet-whey-protein" mdxType="NavLinkGlobal">
    Wheyprotein
  </NavLinkGlobal>{" "}
  und schnell verfügbare Kohlenhydrate. Eine sinnvolle Alternative für die
  Banane in dieser Mahlzeit wären{" "}
  <AffiliateLink url={props.pageContext.frontmatter.product_instant_oats_url} mdxType="AffiliateLink">
    {props.pageContext.frontmatter.product_instant_oats_text}
  </AffiliateLink>
  . Es handelt sich hierbei um sehr gut lösliche Haferflocken, die Du einfach in
  den gleichen Shaker oder Mixer geben kannst wie das Wheyprotein. Sie versorgen
  dich mit komplexen Kohlenhydraten, die lange satt machen und einen niedrigen glykämischen
  Index aufweisen. Um auf die gleiche Kalorienmenge zu kommen tausche einfach eine
  Banane (125 g) gegen eine Portion Instant Oats (30 g).
    </p>
    <Image image={getImage(props.data.mdx.frontmatter.image_third)} imageAlt={props.data.mdx.frontmatter.image_third_alt} imageDesc={props.data.mdx.frontmatter.image_third_desc} imageAuthor={props.data.mdx.frontmatter.image_third_credit_text} imageLink={props.data.mdx.frontmatter.image_third_credit_link} mdxType="Image" />
    <h2>{`So überwindest du Plateaus und beschleunigst deinen Stoffwechsel...`}</h2>
    <p>
  Kommen wir nun zu einem sehr beliebten und gleichzeitig sehr kontrovers
  diskutierten Thema, nämlich dem <b>Cheatday</b>. Dieser ist auch bekannt unter
  den Begriffen <b>Carb Backloading</b>, <b>Refeed</b>, <b>Fresstag</b> oder
  auch <b>Ladetag</b>. Es gibt selbst ernannte Fitness-Gurus, die sich hierbei
  die wildesten und kreativsten Vorgaben ausdenken. Einige davon geben eine
  feste Makronährstoffverteilung und eine nur leicht erhöhte Kalorienmenge vor.
  Andere wiederum sind Verfechter eines sehr einfachen Ansatzes, der sich als
  "iss alles, was du willst und so viel du willst, aber am nächsten Tag ist
  wieder Diät" zusammenfassen lässt.
    </p>
    <p>
  Um dich nicht zu lange auf die Folter zu spannen, sollst du wissen, dass ich
  mich eher als Verfechter des letzteren Ansatzes sehe. Philipp hat{" "}
  <b>alle zwei Wochen an einem Wochentag seiner Wahl</b>, meistens ein Samstag
  oder auch Feiertag, im wahrsten Sinne des Wortes alles gegessen und getrunken,
  was er wollte ohne sich dabei einzuschränken oder gar sein Essen zu wiegen.{" "}
  <b>
    Fastfood à la Burger mit Pommes, Pizza, Eis, Chips, Cola, Süßigkeiten oder
    andere Leckereien
  </b>{" "}
  sind an diesem einen Tag kein Problem. Iss frei all das, worauf du Lust hast.
  Ob es funktioniert? Die Antwort findest du auf den Vorher-Nachher-Fotos von
  Philipp!
    </p>
    <p>
  <b>Doch wieso funktioniert dieser Ansatz?</b> Je länger du deine Diät
  verfolgst, desto großer wird das Risiko, dass dein Stoffwechsel einschläft.
  Dein Körper geht durch die geringe Kalorienzufuhr in den Überlebensmodus und
  schaltet auf Sparflamme. Durch einen Cheatday alle zwei Wochen, übersättigst
  du deinen Organismus mit Kalorien und Kohlenhydraten, sodass dein Körper seine
  Stoffwechselrate wieder hochfährt. Schließlich hat er jetzt den Eindruck, dass
  eine ausreichende Kalorienversorgung gewährleistet ist.
    </p>
    <p>
  <b>
    Ein anderer Grund für die Effektivität dieses Ansatzes ist psychischer Natur
  </b>
  . Was stehst du mental eher durch, zu wissen, dass du das erste Mal deine Lieblingsspeise
  wieder in einem Jahr essen kannst, oder die Gewissheit alle zwei Wochen nach Lust
  und Laune zu essen? Deine Diät unterteilt sich so in kurze Sprints und am Ende
  jedes kleinen Sprints steht eine große Belohnung. Dadurch konditionierst du dich
  auf Erfolg, du belohnst dich für deinen Ergeiz und deine Disziplin.
    </p>
    <h2>{`Effektiver Abnehmen mit dieser Strategie!`}</h2>
    <p>
  Hast du dich gefragt, weshalb du an Trainigstagen sowie an trainingsfreien
  Tagen das Gleiche essen solltest? Vor allem hast du dich vielleicht auch
  gefragt, wieso in beiden Fällen die gleiche Menge an Kalorien und
  Makronährstoffen zugeführt werden sollte. Tatsächlich ist das ein sehr guter
  Einwand! Und bei Philipp haben wir uns dieses Wissen zu Nutze gemacht, um
  gleichzeitig Fett ab- und Muskulatur aufzubauen. Du erfährst jetzt wie wir
  vorgegangen sind.
    </p>
    <p>
  Bei fortschreitendem Diäterfolg mussten wir allmählich Philipps Kalorien noch
  etwas reduzieren, um weiterhin auf Erfolgskurs zu bleiben. Dennoch ist es für
  den langfristigen Erfolg wichtig gewesen, dass Philipp zu keiner Zeit hungert.
  Deshalb habe Ich zwei verschiedene Ernährungspläne erstellt, einer für
  Trainingstage der andere für trainingsfreie Tage. Hierbei liegen die Kalorien
  für Trainigstage bei <b>2015 kcal</b> und damit fast unverändert zum
  Diätanfang. Die Kalorien an trainingsfreien Tagen wurden auf <b>1535 kcal</b>{" "}
  reduziert, da der Kalorienbedarf und -verbrauch entsprechend geringer
  ausfällt. Der Kalorienunterschied zeichnet sich vor allem durch eine Reduktion
  der Kohlenhydrate an trainingsfreien Tagen aus, da diese insbesondere für ein
  intensives Training benötigt werden.
    </p>
    <h3>{`Diätplan an Trainingstagen`}</h3>
    <p>
  Schnelles und effektives Abhnehmen ohne zu hungern ist möglich. Auch ist es
  deutlich einfacher als du es dir womöglich vorgestellt hast. Das Geheimnis
  sind unter anderem möglichst unverarbeitete Nahrungsmittel. Sie machen dich
  lange satt und liefern dir wichtige Nährstoffe, damit dein Körper auf
  Hochtouren läuft. In diesem Abschnitt erfährst du, wie Philipp sich zum Ende
  seiner Diät an Trainingstagen ernährt hat.
    </p>
    <p>
  Du wirst erkennen, dass es zwischen diesem und dem ersten Ernährungsplan
  gewisse Ähnlichkeiten gibt. Die Ernährung wurde nicht komplett umgestellt, was
  den Vorteil hat, dass Philipp einerseits eine Abwechslung in der Ernährung
  bekommen hat und sich andererseits nicht komplett umstellen musste. Zu den
  jeweiligen Mahlzeiten wirst du jetzt kurze Erklärungen bekommen.
    </p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Mahlzeit`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Lebensmittel`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Menge`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Frühstück`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><AffiliateLink url={props.pageContext.frontmatter.product_whey_url} mdxType="AffiliateLink">{`Whey Protein`}</AffiliateLink></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40 g`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><AffiliateLink url={props.pageContext.frontmatter.product_instant_oats_url} mdxType="AffiliateLink">{`Instant Oats`}</AffiliateLink></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`30 g`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Walnussöl`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`7 g`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Mittagessen`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Brokkoli`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`100 g`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hähnchenbrustfilet`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`350 g`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Reis`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`110 g`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Abendessen`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Magerquark`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`500 g`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Eier`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3 Stk.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Snack`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><AffiliateLink url={props.pageContext.frontmatter.product_whey_url} mdxType="AffiliateLink">{`Whey Protein`}</AffiliateLink></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`50 g`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Banane`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`125 g`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Frühstück`}</h4>
    <p>
  Um deinen Körper nach der Nachtruhe aus einer katabolen, also
  muskelabbauenden, Stoffwechsellage zu bringen, ist es wichtig schnell
  verfügbare Eiweiße in Kombination mit komplexen Kohlenhydraten zu dir zu
  nehmen. Zu diesem Zweck ist eine Portion{" "}
  <NavLinkGlobal to="/de/blog/was-bedeutet-whey-protein" mdxType="NavLinkGlobal">
    Wheyprotein
  </NavLinkGlobal>{" "}
  mit{" "}
  <AffiliateLink url={props.pageContext.frontmatter.product_instant_oats_url} mdxType="AffiliateLink">
    {props.pageContext.frontmatter.product_instant_oats_text}
  </AffiliateLink>{" "}
  optimal. Abgerundet wird das Frühstück mit einem Esslöffel Walnussöl oder
  Leinöl. Diese Zutaten können einfach in einen Shaker oder Mixer gegeben
  werden. Als Flüssigkeit empfiehlt sich fettarme (laktosefreie) Milch,
  Haferfmilch oder einfach Wasser.
    </p>
    <h4>{`Mittagessen`}</h4>
    <p>
  Mit einer großzügigen Portion Reis liefert dir das Mittagessen die größte
  Menge an Kohlenhydraten. Als Quelle für Ballaststoffe gibst du am besten
  Brokkoli zur üppigen Portion Hähnchenbrustfilet hinzu. Brokkoli ist nicht nur
  eine Quelle für Ballaststoffe, sondern dient zudem als Magenfüller und hat als
  basisches Lebensmittel äußerst wichtige gesundheitliche Eigenschaften.
  Basische Lebensmittel erhöhen den pH-Wert deines Blutes, sie sorgen also
  dafür, dass es weniger "sauer" ist. Dies kann sich positiv auf deine Gelenke
  und deine Regeneration auswirken!
    </p>
    <h4>{`Abendessen`}</h4>
    <p>
  Das Abendessen ist in diesem Plan sehr simpel gehalten. Es besteht aus einer
  Packung Magerquark und 3 ganzen Eiern. Das Ziel ist es durch diese Kombination
  aus Eiweißen und Fetten deinen Blutzuckerspiegel vor der Nachtruhe möglichst
  niedrig zu halten. So baust du beim Schlafen Fett ab und durch den Ausstoß von
  Wachstumshormonen wie HGH und IGF-1 Muskulatur auf. Du kannst den Quark mit
  frischen oder gefrorenen Früchten geschmacklich und nährwerttechnisch
  aufwerten. Die Zubereitung der ganzen Eier bleibt voll und ganz dir
  überlassen, du kannst sie z.B. mit frischem Schnittlauch und Kräutern braten
  und mit einer Tomate und Salatgurke servieren.
    </p>
    <h4>{`Snack`}</h4>
    <p>
  Der Snack ist insbesondere als Mahlzeit nach dem Sport gedacht und erfordert
  praktisch keine Vorbereitungszeit. Für den Fall, dass du unterwegs sein
  solltest, kannst du einfach das Wheyprotein in einen Shaker geben und zusammen
  mit der Banane einpacken. So hast du eine vollwertige kleine Mahlzeit dabei,
  die deinen Körper mit schnell verfügbaren Eiweißen und kurzkettigen
  Kohlenhydraten versorgt. Insbesondere direkt nach dem Training führt das zu
  einer strategisch wichtigen Ausschüttung von Insulin, welches dafür sorgt,
  dass Aminosäuren schnell und effektiv verstoffwechselt werden können und in
  deine Muskulatur wandern.
    </p>
    <h3>{`Diätplan an trainingsfreien Tagen`}</h3>
    <p>
  Das ist der Ernährungsplan für Tage an denen Philipp nicht trainiert hat. Er
  ist also das Gegenstück zu dem oben beschriebenen Diätplan. Die Kalorien sind
  hier von <b>2015 kcal</b> auf <b>1535 kcal</b> verringert. Wie bereits oben
  einleitend erwähnt, resultiert dies vor allem aus einer verringerten
  Kohlenhydratmenge. Insbesondere die Eiweißzufuhr ist relativ hoch gehalten, um
  die Muskulatur mit Aminosäuren zu versorgen, was einem Muskelabbau
  entgegenwirkt. Das ist für deine Diätbemühungen äußerst wichtig, da es dir
  zunehmend schwerer fallen wird abzunehmen, je weniger Muskulatur du hast.
  Weitere Infos zu diesem Thema findest du in diesem Artikel über die{" "}
  <NavLinkGlobal to="/de/blog/wie-berechne-ich-meinen-kcal-bedarf" mdxType="NavLinkGlobal">
    Berechnung der Kalorienzufuhr
  </NavLinkGlobal>
  .
    </p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Mahlzeit`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Lebensmittel`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Menge`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Frühstück`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Banane`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`125 g`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hüttenkäse`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`200 g`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Reiswaffeln`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4 Stk.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Mittagessen`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Zwiebeln`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`100 g`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hähnchenbrustfilet`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`400 g`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Zucchini`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`150 g`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Abendessen`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Champignons`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`100 g`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Eier`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`5 Stk.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Snack`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><AffiliateLink url={props.pageContext.frontmatter.product_whey_url} mdxType="AffiliateLink">{`Whey Protein`}</AffiliateLink></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`50 g`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Frühstück`}</h4>
    <p>
  Das Frühstück besteht aus den zwei Kohlenhydratquellen Banane und Reiswaffeln,
  was mit einer Packung Hüttenkäse als Eiweißquelle abgerundet wird. Die
  Kohlenhydrate stellen hierbei einen Mix aus einfachen und komplexen
  Kohlenhydraten dar. Die Eiweiße des Hüttenkäses setzen sich überwiegend aus{" "}
  <NavLinkGlobal to="/de/blog/fuer-was-ist-casein-gut" mdxType="NavLinkGlobal">Casein</NavLinkGlobal>{" "}
  zusammen. Sie versorgen dich also länger mit Aminosäuren.
    </p>
    <h4>{`Mittagessen`}</h4>
    <p>
  Im Gegensatz zum Ernährungsplan an Trainigstagen, enthält das Mittagessen an
  trainingsfreien Tagen keine direkte Kohlenhydratquelle. Die Zwiebeln und die
  Zucchini sind eine Quelle für Ballaststoffe, sowie ein angenehmer
  Geschmacksträger. Außerdem sorgen sie dafür, dass die Portion
  Hähnchenbrustfilet schmackhafter zubereitet werden kann. Die Portion des
  Hähnchenbrustfilets ist relativ üppig gehalten, was von strategischer
  Bedeutung ist. Eiweißhaltige Nahrungsmittel haben den großen Vorteil, dass sie
  lange satt machen und dich gleichermaßen mit wichtigen Aminosäuren versorgen.
  So bleibt dein Blutzuckerspiegel konstant niedrig und du nimmst schnell und
  effektiv ab!
    </p>
    <Image image={getImage(props.data.mdx.frontmatter.image_fourth)} imageAlt={props.data.mdx.frontmatter.image_fourth_alt} imageDesc={props.data.mdx.frontmatter.image_fourth_desc} imageAuthor={props.data.mdx.frontmatter.image_fourth_credit_text} imageLink={props.data.mdx.frontmatter.image_fourth_credit_link} mdxType="Image" />
    <h4>{`Abendessen`}</h4>
    <p>
  Das Abendessen stellt wie das Mittagessen eine Low-Carb Mahlzeit dar. Die
  Strategie dahinter ist, dass Philipp nur morgens Kohlenhydrate zu sich
  genommen hat, um anschließend während des restlichen Tagesverlaufs besser
  Körperfett verstoffwechseln zu können. Die Eier sind sowohl eine Quelle für
  Eiweiß als auch für gesunde gesättigte Fettsäuren. Zudem enthalten Eier
  gesundes HDL Cholesterin und sind eine Quelle für Lecithin, was die
  Leistungsfähigkeit des Gehirns erhöhen kann. Die Champignons als Magenfüller
  und Liefrant für Mikronährstoffe wurden von Philipp zu den Eiern gegeben, um
  daraus ein leckeres Omelett zu zaubern.
    </p>
    <h4>{`Snack`}</h4>
    <p>
  Der Snack besteht aus einem üppigen{" "}
  <NavLinkGlobal to="/de/blog/was-bedeutet-whey-protein" mdxType="NavLinkGlobal">
    Wheyprotein
  </NavLinkGlobal>{" "}
  Shake. Der Einnahmezeitpunkt kann hier sehr flexibel gestaltet werden und
  sollte als leckerer Shake für Zwischendurch bei kleinem Hunger verzehrt
  werden. Besonders mit einer leckeren Geschmackssorte kann er deine Stimmung in
  einer Diät maßgeblich verbessern.
    </p>
    <h2>{`Diese Sportarten unterstützen dich beim Abnehmen...`}</h2>
    <p>
  Genau wie Philipp hast vielleicht auch Du den Wunsch abzunehmen und etwas
  Muskulatur aufzubauen, um endlich deine Traumfigur Wirklichkeit werden zu
  lassen. Wie Philipp sich dabei ernährt hat, habe ich dir bereits verraten.
  Doch wie steht es um das Training? Schließlich gibt es unzählig viele
  Sportarten und es kommen gefühlt monatlich neue Modesportarten hinzu. Was du
  beim Sport wirklich beachten musst, und welche Sportkombination die mit
  Abstand beste ist, wirst du jetzt erfahren.
    </p>
    <h3>{`Um abzunehmen musst du Muskeln aufbauen!`}</h3>
    <p>
  Du weißt bereits, dass Du zum Abnehmen mehr Kalorien verbrauchen musst, also
  du zu dir nimmst. Da erscheint es doch naheliegend einer Sportart nachzugehen,
  bei der man sich viel bewegt, schwitzt und einfach viele Kalorien verbrennt,
  um eine negative Kalorienbilanz zu erreichen. Schnell denken wir an
  Aktivitäten wie Joggen, Fußball, Stepper, Fahrradfahren oder Aerobickurse.
  Kalorien verbrauchen wirst du hierbei viele, aber wirst du damit auch wirklich
  schlank und das langfristig? Die Antwort ist ein klares <b>NEIN</b>! Das mag
  sich für dich wie ein Schock anhören, aber die Erklärung ist ziemlich einfach.
  Dein Körper wird alles unternehmen, um seine Energiereserven, also seine
  Fettdepots zu schützen. Das ist die Art und Weise wie wir durch die Evolution
  programmiert sind.
    </p>
    <p>
  Nachdem dein Körper bei einer negativen Energiebilanz in den "Überlebensmodus"
  schaltet, wird er deine Muskulatur verstoffwechseln, um so seinen{" "}
  <NavLinkGlobal to="/de/blog/wie-berechne-ich-meinen-kcal-bedarf" mdxType="NavLinkGlobal">
    Grundumsatz
  </NavLinkGlobal>{" "}
  zu senken. Als Folge wird es immer schwerer für dich weiter abzunehmen, bis
  deine Bemühungen schlicht zum Erliegen kommen. Doch was können wir dagegen
  tun? Zum einen ist eine eiweißreiche Ernährung fundamental wichtig, um mit den
  zugeführten Aminosäuren deine Körpermuskulatur zu schützen. Zum anderen musst
  Du dafür sorgen, dass deine Muskulatur ausreichend stimuliert wird, um einer
  Muskelatrophie vorzubeugen. Zustätzlich wäre es natürlich wünschenswert
  darüber hinausgehend Muskulatur aufzubauen. Dadurch steigt dein{" "}
  <NavLinkGlobal to="/de/blog/wie-berechne-ich-meinen-kcal-bedarf" mdxType="NavLinkGlobal">
    Grundumsatz
  </NavLinkGlobal>
  , und du verbrauchst auch in Ruhe mehr Kalorien. Deine Diätbemühungen werden dir
  immer leichter fallen und du wirst auch langfristig in der Lage sein mehr zu essen,
  ohne zuzunehmen.
    </p>
    <h3>{`Philipps Trainingsplan zum Erfolg`}</h3>
    <p>
  Der folgende Krafftrainingsplan besteht aus den 3 Einheiten <b>Beine</b>,{" "}
  <b>Push</b> und <b>Pull</b>, die jeweils an einem Trainingstag ausgeführt
  werden. Dieser Trainingsplan wurde 3 bis 4 mal pro Woche von Philipp
  absolviert. Wenn Du also bei der dritten Einheit Pull trainiert hast, dann
  geht der Plan wieder von vorne los und Du würdest bei der vierten Einheit
  wieder Beine trainieren. Zwischen jedem Satz sollte eine Verschnaufpause von
  etwa 90 Sekunden bzw. 1,5 Minuten liegen.
    </p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Muskelgruppen`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Übung`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Sätze x Wdh.`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Beine`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`LH-Kniebeugen`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3 x 8-10`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Beinpressen`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3 x 12-14`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Beinstrecken`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2 x 12-14`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Liegender Beincurl`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6 x 8-10`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Wadenheben`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6 x 16-20`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Push`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`LH-Schrägbankdrücken`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3 x 6-10`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Butterfly`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3 x 12-14`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`KH-Schulterdrücken`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3 x 6-10`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`KH-Seitheben`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2 x 12-14`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Seil-Trizepsstrecken`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3 x 6-10`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SZ Frenchpress`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2 x 12-14`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Pull`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Klimmzüge`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3 x 6-10`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`KH-Rudern`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3 x 12-14`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Seilrudern sitzend`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2 x 16-20`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`KH-Bizepscurls`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3 x 6-10`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bizepsmaschine`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2 x 12-14`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Beintraining`}</h4>
    <p>
  Das gesamte Unterkörpertraining besteht aus 5 Übungen, die deine vorderen und
  hinteren Oberschenkel sowie deine Waden trainieren. Ich verwende für die
  verschiedenen Hantelarten die Kürzel <b>LH = Langhantel</b> und{" "}
  <b>KH = Kurzhantel</b>. Nachdem Du dich ausgiebig aufgewärmt hast, führst du
  als erste Übung Kniebeugen aus. Hierbei handelt es sich um eine Grundübung,
  die nicht nur deine Oberschenkel, sondern indirekt deinen gesamten Körper
  beansprucht. Als Folgeübungen kommen Beinpressen und Beinstrecken, die jeweils
  deine vorderen Oberschenkel, also den Quadrizeps (Beinstrecker), als
  Primärzielmuskel stimulieren. Als abschließende Oberschenkelübung folgt die
  Isolationsübung Beincurl, mit der Du primär deinen Beinbizeps also den
  Beinbeuger, belastest. Das Abschluss des Beintrainings stellt die Übung
  Wadenheben dar mit der, wie der Name schon verrät, die Wadenmuskulatur
  trainiert wird.
    </p>
    <h4>{`Push`}</h4>
    <p>
  Unter dem Begriff <b>Push</b> werden die Übungen zusammengefasst, bei der ein
  Gewicht vom Körper <b>weg</b> bewegt wird. Die entsprechende Muskulatur führt
  hierbei eine <b>streckende</b> Bewegung aus. Zu diesen Muskelgruppen gehören
  die <b>Brustmuskulatur</b>, die <b>Schultern</b> und der Armstrecker bzw. der{" "}
  <b>Trizeps</b>. Die Einheit beginnt mit Übungen für die Brustmuskulatur,
  nämlich mit Schrägbankdrücken und Butterfly. Hierbei kann letztere Übung
  entweder an der Maschine, am Seilzug oder mit Kurzhanteln ausgeführt werden.
  Gefolgt werden die Brustübungen mit dem Schultertraining. Dieses setzt sich
  aus dem vertikalen Schulterdrücken und dem Seitheben, entweder stehend oder
  sitzend, zusammen. Den Abschluss der Trainingseinheit bilden die zwei Übungen
  Trizepsstrecken und SZ Frenchpress, auch bekannt als "Stirndrücken" bzw.
  "Scullcrusher".
    </p>
    <h4>{`Pull`}</h4>
    <p>
  Die <b>Pull-Einheit</b> stellt von ihrem Wesen das Gegenstück zur Push-Einheit
  dar. Trainiert werden hier Muskelgruppen, welche eine{" "}
  <b>beugende Bewegung zum Körper hin</b> ausführen. Du stimulierst also bei
  dieser Einheit deine gesamte Rücken- und Armbeugemuskulatur, letztere ist auch
  unter dem Begriff "Bizeps" bekannt. Das Training beginnt mit 3 Sätzen
  Klimmzügen, die du auch gegen die Übung Latzug am Kabelzug auswechseln kannst.
  Dies ist vor allem dann wichtig, wenn du noch nicht über die notwendige Kraft
  verfügst, um freie Klimmzüge ausführen zu können. Im Anschluss folgen die
  beiden Ruderübungen Rudern mit der Kurzhantel und eine weitere Rudervariante
  sitzend am Kabelzug. Durch diese Vielfalt an Übungen wird die komplexe
  Rückenmuskulatur von allen Winkeln erreicht. Die Einheit wird mit 5 Sätzen für
  den Bizeps abgeschlossen, welche sich auf die Übungen Bizepscurls mit der
  Kurzhantel und Bizepscurls an der Maschine bzw. am Kabelzug verteilen.
    </p>
    <h3>{`Cardiotraining sollte kurz und intensiv sein!`}</h3>
    <p>
  Obwohl der Fokus deines Training auf Krafftraining liegen sollte, ist es
  dennoch wichtig auch in moderatem Umfang Aktivitäten nachzugehen, die gezielt
  Kalorien verbrennen. Wir sprechen hier also von aerobem Training, gerne auch
  als <b>Cardio-Training</b> bezeichnet. Tatsächlich haben wir es hier bei
  Philipp einfach gehalten. Es war ihm wichtig, das Cardio-Training möglichst
  zeiteffektiv und in geringem Volumen zu gestalten. Das Ergebnis war, dass
  Philipp im Anschluss an zwei bis vier Krafftrainingseinheiten ein intensives
  15-minütiges Cardiotraining auf dem Stepper oder Crosstrainer ausgeführt hat.
  Das ist Dir zu langweilig? Egal ob Aerobic, Rennradfahren, Rudern,
  Fußballspielen oder Volleyball, mach hier einfach das, was dir Spaß macht, so
  hast am meisten davon!
    </p>
    <h3>{`Bauchmuskeltraining ist überbewertet!`}</h3>
    <p>
  Bestimmt ist dir aufgefallen, dass Philipps Trainingsplan kein explizites{" "}
  <b>Bauchtraining</b> enthält. Bauchtraining kannst Du grundsätzlich im
  Anschluss an jedes Training ausführen. Deshalb hat auch Philipp im Anschluss
  an mindestens eines seiner Trainingseinheiten eine Bauchübung in Form von{" "}
  <b>Beinheben mit 3 Sätzen im Wiederholungsbereich von 20-25</b> absolviert.
  Beinheben ist eine hervorragende Übung für den Bauch, da sie grundsätzlich den
  gesamten Bauch stärkt und im Speziellen die untere Bauchmuskulatur (V-Cut /
  V-Muskel) stimuliert.
    </p>
    <p>
  <b>Doch gibt es ein spezielles Training, was gegen Bauchfett hilft?</b> Die
  Körperfettverteilung unseres Körpers ist zum einen genetisch bedingt und zum
  anderen ist sie auch geschlechtsabhägig. Die einen nehmen eher am Bauch zu,
  andere eher an der Hüfte und wiederum andere eher an den Beinen oder dem
  Gesäß. Das bedeutet, dass du nicht gezielt an einer bestimmten Körperstelle
  Fett abbauen kannst, wie es dir vielleicht einige unseriöse TV-Spots beim
  Verkauf ihrer Produkte versprechen. <b>Es gilt nach wie vor der Grundsatz:</b>{" "}
  du nimmst ab, wenn du eine{" "}
  <NavLinkGlobal to="/de/blog/wie-berechne-ich-meinen-kcal-bedarf" mdxType="NavLinkGlobal">
    negative Kalorienbilanz
  </NavLinkGlobal>{" "}
  hast. Wo du jetzt am schnellsten bzw. am ehesten abnimmst, kannst du nicht
  beeinflussen.
    </p>
    <p>
  <b>Heißt das jetzt Bauchtraining ist unnötig?</b> Nein absolut nicht! Eine
  starke Bauchmuskulatur ist gesundheitlich von enormer Bedeutung. Sie sorgt für
  eine gesunde Körperhaltung und untersützt bzw. stabilisiert deine Wirbelsäule.
  Eine ausgeprägte Bauchmuskulatur ist zudem auch bei einem höheren
  Körpfettanteil sichtbar. Das wiederum bedeutet, dass du zu einem deutlich
  früheren Punkt deiner Diät ein sichtbares Sixpack haben wirst. Das sind viele
  Gründe, wieso ein regelmäßiges Bauchtrainig fester Bestandteil deines
  Trainings sein sollte.
    </p>
    <h2>{`FAQ - Häufig Gestellte Fragen`}</h2>
    <p>
  Kennst du das Gefühl, wenn du dich seit einer gefühlten Ewigkeit zu einem
  Thema schlau liest, du dann aber enttäuscht merkst, dass genau <b>DEINE</b>{" "}
  besondere Frage unbeantwortet bleibt? Kenne ich nur zu gut, deshalb bekommst
  du hier einen Fragen-Antworten Katalog mit dem auch deine speziellste Frage
  beanwortet wird. Los geht's!
    </p>
    <Image image={getImage(props.data.mdx.frontmatter.image_faq)} imageAlt={props.data.mdx.frontmatter.image_faq_alt} imageDesc={props.data.mdx.frontmatter.image_faq_desc} imageAuthor={props.data.mdx.frontmatter.image_faq_credit_text} imageLink={props.data.mdx.frontmatter.image_faq_credit_link} mdxType="Image" />
    <h3>{`Was sollte man abends essen wenn man abnehmen will?`}</h3>
    <p>
  Abends solltest du vor allem auf Kohlenhydrate verzichten und dich eiweiß- und
  fetthaltig ernähren. Eiweißhaltige Nahrungsmittel sind z.B. Käse, Quark,
  Hüttenkäse, Eier, Eiklar, Fleisch, Fisch, Linsen, Bohnen, (Kicher)erbsen oder
  Eiweißkonzentrate. Bei den Fettquellen solltest du in erster Linie darauf
  achten, dass sie mehrfach ungesättigte Fettsäuren enthalten. Gute Quellen sind
  hier Walnüsse, Mandeln, Paranüsse, Leinöl, Walnussöl, Oliven(öl), fetthaltiger
  Fisch oder alternativ Fischöl.
    </p>
    <p>
  Du kannst hier gerne die beiden Ernährungspläne nutzen, die in diesem Artikel
  beschrieben wurden oder einfach deiner Kreativität freien Lauf lassen. Wie
  wäre es mit gegrilltem Hähnchenbrustfilet mit einem leckeren Salat zu dem du
  Walnüsse und ein Dressing auf olivenölbasis gibst. Oder schlicht und einfach
  Quark mit frischen oder gefrorenen Früchten und einem Esslöffel Walnuss- oder
  Leinöl. Du wirst schnell herausfinden, was dir am besten schmeckt und für dich
  am besten funktioniert.
    </p>
    <h3>{`Wie nimmt man am besten ab?`}</h3>
    <p>
  Das ist eine häufig gestellte und auf den ersten Blick einfach zu
  beantwortende Frage. Leider tun sich die meisten schwer fundierte und
  objektive Informationen hierzu zu finden. Wenn du den Artikel vollständig
  gelesen hast, wirst du bereits einen guten Eindruck davon haben, wie du
  erfolgreich und langfristig abnehmen kannst. Auch wirst du in diesem Blog in
  Zukunft weitere Artikel zu diesem Thema finden.
    </p>
    <p>
  Doch um jetzt mal auf den Punkt zu kommen, solltest du dir folgendes merken.
  Am besten nimmst du ab, indem du eine negative Kalorienbilanz hast, bei der du
  nur leicht unter deinem Grundumsatz bist. Dadurch stellst du sicher, dass dein
  Stoffwechsel nicht heruntergefahren wird, um Kalorien zu sparen. Auch solltest
  du dich möglichst eiweißreich ernähren, mit moderaten Mengen an gesunden
  Fetten. Erfahrungsgemäß ist eine kohlenhydratarme Diät in der Regel die
  optimalste Strategie. Das Training sollte aus einem Mix aus Kraft- bzw.
  Muskelaufbautraining und leichtem Ausdauertraining bestehen. Ausdauertraining
  bedeutet hierbei aber natürlich nicht, dass du Sprint- oder Marathonprofi
  werden musst.
    </p>
    <h3>{`Wie kann ich am schnellsten 10 kg abnehmen?`}</h3>
    <p>
  Bei einem Kaloriendefizit von <b>300 kcal pro Tag</b> nimmst du rechnerisch{" "}
  <b>1,2 kg reines Körperfett pro Monat</b> ab. Das bedeutet, dass du in{" "}
  <b>unter 9 Monaten 10 kg reines Körperfett</b> verloren hast. Wie du jedoch
  anhand von Philipps Erfolgsgeschichte sehen konntest, ist in der gleichen Zeit
  auch eine mehr als doppelt so hohe Fettabnahme möglich. Konzentriere dich
  hierbei nicht so sehr auf die Schnelligkeit oder den reinen Wert auf der
  Waage. Dein Ziel ist von visueller Natur, deinen Traumkörper willst du im
  Spiegel sehen, was deine Waage sagt ist zweitrangig. Alle Informationen, die
  du dafür brauchst findest du in diesem Artikel.
    </p>
    <h3>{`Wie lange dauert es bis man mit Krafttraining abnimmt?`}</h3>
    <p>
  Einzig und allein entscheidend für eine Körpergewichts- bzw. Fettabnahme ist
  eine negative Kalorienbilanz. Nur wenn du weniger Kalorien durch Nahrung zu
  dir genommen, als du verbraucht hast, wirst du abnehmen. Ist Krafftraining
  deshalb ineffektiv? Ganz und gar nicht! Erstens verbrauchst du beim
  Krafttraining Kalorien. Zweitens führt Krafttraining dazu, dass du Muskulatur
  aufbaust, welche deinen Grundumsatz erhöht. Du verbrauchst folglich auch in
  Ruhe mehr Kalorien als vorher. Dadurch wird es für dich einfacher auf eine
  negative Kalorienbilanz zu kommen, um deine Diätziele schneller zu erreichen.
    </p>
    <h3>{`Soll ich zuerst abnehmen und dann Muskeln aufbauen?`}</h3>
    <p>
  Philipps Transformation zeigt eindrucksvoll, dass du beides gleichzeitig
  erreichen kannst und dementsprechend beides gleichzeitig tun solltest. Der
  beim Krafttraining erzielte Muskelaufbau erhöht deinen Grundumsatz, was dir
  wiederum beim Abnehmen enorm hilft. Auch hilft dir das Krafttraining dabei,
  deine Stoffwechselrate auf einem konstant hohen Niveau zu halten. Betrachte
  also Krafttraining als eine fundamentale Aktivität, welche beim Abnehmen
  unverzichtbar ist.
    </p>
    <h2>{`Was hast du in diesem Artikel gelernt?`}</h2>
    <p>
  Dieser Artikel hat dich mit auf Philipps Reise zur Wunschfigur begleitet. Du
  weißst jetzt alles über seine Ernährung und sein Training. Seine
  Vorher-Nachher-Fotos geben seiner Strategie Recht und beweisen eindeutig ihre
  Effektivität. Es gibt hierbei keine Geheimnisse und keine Informationen, die
  sich hinter einem Preisschild verstecken. Du kannst problemlos die Pläne
  übernehmen, damit auch du deine Ziele erreichst!
    </p>
    <ul>
      <li parentName="ul">{`Du brauchst einen Ernährungsplan, um deine Körperziele zu erreichen.`}</li>
      <li parentName="ul">{`Nutze am besten einen zuverlässigen `}<NavLinkGlobal to="/de/#appstores" mdxType="NavLinkGlobal">{`Kalorienzähler`}</NavLinkGlobal>{`, um deine Diät zu tracken.`}</li>
      <li parentName="ul">{`Du kannst deinen Ernährungsplan in Trainingstage und freie Tage unterteilen.`}</li>
      <li parentName="ul">{`Du solltest alle zwei Wochen einen Cheatday einlegen.`}</li>
      <li parentName="ul">{`Muskelaufbautraining ist von großer Bedeutung für deine Diät.`}</li>
      <li parentName="ul">{`Cardiotraining kann dich beim Abnehmen unterstützen.`}</li>
      <li parentName="ul">{`Führe ein `}<NavLinkGlobal to="/de/#appstores" mdxType="NavLinkGlobal">{`Gewichtsprotokoll`}</NavLinkGlobal>{`, um deinen Erfolg zu messen.`}</li>
    </ul>
    <Disclosure mdxType="Disclosure" />
    <AppPromotion mdxType="AppPromotion" />
    <BlogOverview mdxType="BlogOverview" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      